
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}


/* Typing animation */
@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  .typing-animation {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    border-right: 2px solid transparent; /* Adjust border properties as needed */
    animation: typing 1.5s steps(30, end) 1;
  }
  .loader {
    font-size: 48px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #0886f5;
    letter-spacing: 2px;
    position: relative;
  }
  .loader::after {
    content: 'Loading';
    position: absolute;
    left: 0;
    top: 0;
    color: #ef8216;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 10s ease-in infinite;
  }
  
  @keyframes animloader {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }